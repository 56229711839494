<template>
  <div id="navi" class="nav">
    <li v-for="menu in naviItem" :key="menu.name">
      <RouterLink :to="menu.url" :class="[menu.icon, { 'selected' : activeIdx==menu.name } ]" @click="handleMenuFn(menu.name)">{{ menu.name }}</RouterLink>
    </li>
  </div>
</template>

<script setup>

import {ref, onMounted} from "vue";
import { useRoute } from "vue-router";

const route = useRoute();

const naviItem = ref([
  {name: '홈', url:"/", icon: 'navi_home'},
  {name: '카테고리', url:"/category", icon: 'navi_cate'},
  {name: '검색', url:"/search", icon: 'navi_search'},
  {name: 'MY', url:"/mypage", icon: 'navi_mypage'},

]);
const activeIdx = ref(null);
// const firstVal = ref(null); //서비스 첫 진입이나, 새로고침등으로 접근한 경우

function handleMenuFn(idx) {
  activeIdx.value = idx;
  
}

onMounted (() => {
  let currentPath = route.path;
  if(currentPath === '/') {
    activeIdx.value = '홈'
  } else if (currentPath === '/category') {
    activeIdx.value = '카테고리'
  } else if (currentPath === '/search') {
    activeIdx.value = '검색'
  } else if (currentPath === '/mypage') {
    activeIdx.value = 'MY'
  }
  //그 외는 기존에 선택된 값
})


</script>