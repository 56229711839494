import { createRouter, createWebHistory } from 'vue-router'
import fullLayout from '../views/common/fullLayout.vue'
import subLayout from '../views/common/subLayout.vue'


const router = createRouter({
  history: createWebHistory(),

  scrollBehavior(to, from, savedPosition) {
    // 항상 맨 위로 스크롤
    document.body.scrollTo(0,0)
    return { top: 0 ,   behavior: 'smooth'}
  },
  routes: [
    {
      path: '/',
      name: 'Intro',
      redirect: { name: 'main' },
      meta: {
        title: '모든수산 '
      }
    }, {
      path: '/',
      component: fullLayout,
      children: [
        {
          path: '/',
          component: () => import('@/views/Main'),
          name: 'main',
          meta:{
            name:'홈',
            icon: 'navi_home'
          }
        },
        {
          path: '/product',
          component: () => import('@/views/product/product'),
          name: 'product'
        },{
          path: '/product_detail',
          component: () => import('@/views/product/productDetail'),
          name: 'product detail'
        },
        {
          path: '/search',
          component: () => import('@/views/Search'),
          name: 'search',
          meta:{
            name:'검색',
            icon: 'navi_search'
          }
        }, {
          path: '/mypage',
          component: () => import('@/views/mypage/MyPage'),
          name: 'mypage',
          meta:{
            name:'MY',
            icon: 'navi_mypage'
          }
        },{
          path: '/category',
          component: () => import('@/views/Category'),
          name: 'category',
          meta:{
            name:'카테고리',
            icon: 'navi_cate'
          }
        },
        {
          path: "/:pathMatch(.*)*",
          component: () => import('@/views/NotFound.vue'),
          name: "NotFound",
          meta:{
            name:'NoutFound',
            icon: 'navi_cate'
          }
        },
      ]
    },{
      path: '/login',
      component: () => import('@/views/signup/Login'),
      name: 'login',
    },{
      path: '/find_id',
      component: () => import('@/views/signup/findID'),
      name: 'find id',
    },{
      path: '/find_id_done',
      component: () => import('@/views/signup/findIdDone'),
      name: 'find id done',
    },{
      path: '/reset_pw',
      component: () => import('@/views/signup/resetPw'),
      name: 'reset pw',
    },{
      path: '/find_pw',
      component: () => import('@/views/signup/findPw'),
      name: 'find pw',
    },{
      path: '/signup',
      component: () => import('@/views/signup/signUp'),
      name: 'signup',
    },{
      path: '/signup_reg',
      component: () => import('@/views/signup/signUpRegister'),
      name: 'signup register',
    },{
      path: '/entry',
      component: () => import('@/views/signup/Entry'),
      name: 'entry',
    },{
      path: '/cart',
      component: () => import('@/views/Cart'),
      name: 'cart'
    },{
      path: '/purchase',
      component: () => import('@/views/purchase/Purchase'),
      name: 'purchase'
    },{
      path: '/purchase_done',
      component: () => import('@/views/purchase/PurchaseDone'),
      name: 'purchase done'
    },{
      path: '/address',
      component: () => import('@/views/mypage/address'),
      name: 'address'
    },{
      path: '/address_add',
      component: () => import('@/views/mypage/addressAdd'),
      name: 'address Add'
    },{
      path: '/order_list',
      component: () => import('@/views/mypage/OrderList'),
      name: 'order list'
    },{
      path: '/order_detail',
      component: () => import('@/views/mypage/OrderDetail'),
      name: 'order detail'
    }, {
      path: '/privacy_modify',
      component: () => import('@/views/mypage/privacyModify'),
      name: 'privacy modify'
    },{
      path: '/service',
      component: () => import('@/views/signup/service'),
      name: 'service'
    },{
      path: '/privacy',
      component: () => import('@/views/signup/privacy'),
      name: 'privacy'
    },{
      path: '/notice',
      component: () => import('@/views/support/notice'),
      name: 'notice'
    },{
      path: '/notice_detail',
      component: () => import('@/views/support/noticeDetail'),
      name: 'notice detail'
    },{
      path: '/faq',
      component: () => import('@/views/support/faq'),
      name: 'faq'
    },{
      path: '/select_address',
      component: () => import('@/views/mypage/selectAddress'),
      name: 'select address'
    },
  ]}
)

export default router;
