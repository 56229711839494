<template>
  <header style="height: 50px;"> 
    <div class="header_search"
      v-if="route.name === 'main' || route.name=='NotFound'">
    <h1 class="logo" >모든수산</h1>
    </div>
    <h1 v-else-if="route.meta.name"><button class="btn_back" @click="goBack()">이전</button> {{route.meta.name}}</h1>
    <h1 v-else-if="route.name==='product detail'"><button class="btn_back" @click="goBack()">이전</button>  </h1>
    <h1 v-else><button class="btn_back" @click="goBack()">이전</button> {{stripNumbers(route.query.category)|| exhibitionTitle || '검색결과'}} </h1>
    <div class="my_menu">
      <button class="btn_cart" @click="goCart()"><i class="badge" style="margin-top: 4px;" v-if="store.getters.getCart > 0">{{ store.getters.getCart }}</i> </button>
    </div>
  </header>
</template>

<script setup>

import {ref , computed, onMounted, getCurrentInstance} from "vue";
import { useRouter, useRoute } from 'vue-router';
import {useStore} from "vuex";

const store =useStore()

const router = useRouter()
const route = useRoute()

const {proxy} = getCurrentInstance(); 

const exhibitionTitle = computed(()=>{
  const exhibition =  store.getters.getExhibitionList.filter((e)=>e.id === route.query.exhibition)
  return exhibition[0]?.title 
})

function goCart() {
  if (!store.getters.getMyInfo) {
    // alert("로그인이 필요합니다.\n로그인페이지로 이동합니다.")
    let loginNext = {
      nextPath: '/cart',
      nextQuery: null
    }
    store.commit({type: 'setLoginNext', loginNext: loginNext});
    router.push({name: 'login'});
    return;
  }
  router.push({path: '/cart'});
}
function goBack() {
  router.go(-1)
}

function stripNumbers(subCategoryName) {
  return subCategoryName.replace(/^\d+/, '');
}

const getCartCount = () => {
  if(store.getters.getMyInfo) store.dispatch('fetchCartData')
}

onMounted(() => {
  getCartCount()
})

</script>
<style>
.my_menu{
  position:absolute;
  right: 10px;
  z-index: 10;
}
</style>