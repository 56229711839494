import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import http from "@/plugin/http"

export default createStore({
    state: {
        sessionToken: null,
        currentPath : null,
        findId: null,
        myInfo: null,
        exhibitionList : [],
        loginNext: null,
        orderList : [],
        popupState : {},
        cartCount:0
    },
    getters: {
        getSessionToken: (state) =>{
            return state.sessionToken
        },
        getCurrentPath: (state)  => {
            return state.currentPath
        },
        getFindId: (state)  => {
            return state.findId
        },
        getMyInfo: (state)  => {
            return state.myInfo
        },
        getExhibitionList: (state)  => {
            return state.exhibitionList
        },
        getLoginNext: (state)  => {
            return state.loginNext
        },
        getOrderList: (state)  => {
            return state.orderList
        },
        getPopupState: (state) => {
            return state.popupState
        },
        getCart: (state) => {
            return state.cartCount
        },
    },
    mutations: {
        setSessionToken(state,payload) {
            state.sessionToken = payload.token
        },
        setCurrentPath(state, payload) {
            state.currentPath = payload.path
        },
        setFindId(state, payload) {
            state.findId = payload.findId
        },
        setMyInfo(state, payload) {
            state.myInfo = payload.myInfo
        },
        setExhibitionList: (state, payload)  => {
            state.exhibitionList = payload.exhibitionList;
        },
        setLoginNext: (state, payload)  => {
            state.loginNext = payload.loginNext;
        },
        setOrderList: (state, payload)  => {
            state.orderList = payload.orderList;
        },
        setPopupState: (state, payload) => {
            state.popupState = payload.popupState;
        },
        setCartCount:(state, payload) => {
            state.cartCount = payload;
        }
    },
    actions: {
        fetchCartData({ commit }) {
            this.$axios({
                methods: 'get',
                url: '/api/user/cart/list/count'
            })
            .then(response => {
              if (response.status == 200) {
                commit('setCartCount', response.data.count);
              } else {
                alert(response.data.resultMessage)
              }
            })
            .catch(error => {
              if (error.response?.data?.resultMessage) {
                alert(error.response?.data?.resultMessage)
              } else {
                console.log()
                alert("장바구니를 불러오는 도중 오류가 발생했습니다.")
              }
            })
        },
        fetchExhibition({ commit }){
            this.$axios.get('/api/common/exhibition')
            .then(response => {
              if (response.data.resultCode === 0) {
                let exhibitionList = response.data.result.filter((e) =>  e.useYn === 'Y') || []
                console.log(exhibitionList)
                commit("setExhibitionList", {exhibitionList: exhibitionList})
              } else {
                alert(response.data.resultMessage)
              }
            })
            .catch(error => {
              if (error.response?.data?.resultMessage) {
                alert(error.response?.data?.resultMessage)
              } else {
                alert("기획전을 불러오는 도중 오류가 발생했습니다.")
              }
            })
        },
        initState({ commit }){
            commit({type: 'setMyInfo', myInfo: null});
            commit({type: 'setSessionToken', token: null});
            commit({type: 'setFindId', findId: null});
            commit('setExhibitionList', {exhibitionList:[{title : null}]})
            commit('setCartCount', 0);
        }
    },
    plugins: [//새로고침 데이터 유지용 추가
        createPersistedState()
    ],
});