import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router'
import  './assets/css/common.css'

import VueDaumPostcode from 'vue-daum-postcode';
import store from "@/stores/store";
import filters from "@/plugin/filters"
import http from "@/plugin/http"

console.log('HTTP ',http)

const app = createApp(App)


app.config.globalProperties.$axios = http;
app.config.globalProperties.$store = store;
app.config.globalProperties.$filters = filters;

store.$axios = http;

app.use(store);
app.use(router);
app.use(VueDaumPostcode);

app.mount('#app')