<template>
  <Header />
  <Navigation v-if="showNaviList.includes(useRoute().name) || useRoute().name=='NotFound'"/>
  <div id="contents">
      <RouterView />
  </div>

</template>

<script setup>
import { RouterView, useRoute } from 'vue-router';
import Header from './MainHeader.vue';
import Navigation from './Navi.vue';

const showNaviList = ['main','category','search','mypage']

</script>
<style>
.contents{
  min-height: 100vh;
}
</style>